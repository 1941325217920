import { render, staticRenderFns } from "./AnchorLinks.vue?vue&type=template&id=3401b976&scoped=true"
import script from "./AnchorLinks.vue?vue&type=script&lang=js"
export * from "./AnchorLinks.vue?vue&type=script&lang=js"
import style0 from "./AnchorLinks.vue?vue&type=style&index=0&id=3401b976&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3401b976",
  null
  
)

export default component.exports